import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Intelligence = () => {
  const canvasRefs = useRef([]);

  useEffect(() => {
    canvasRefs.current.forEach((el) => {
      if (el) {
        const context = el.getContext("2d");
        const gradient = context.createLinearGradient(0, 0, 0, 170);
        context.beginPath();
        context.moveTo(10, 10);
        context.lineTo(160, 10);
        context.lineTo(210, 60);
        context.lineTo(160, 110);
        context.lineTo(10, 110);
        context.lineTo(60, 60);
        context.closePath();
        context.shadowColor = "white";
        context.shadowBlur = 10;
        context.shadowOffsetX = 3;
        context.shadowOffsetY = 3;
        gradient.addColorStop(0, "#5b9bd5");
        gradient.addColorStop(1, "rgb(78,193,243)");
        context.fillStyle = gradient;
        context.fill();
      }
    });
  }, []);

  const labels = [
    "Profile Optimization",
    "Posting",
    "Engaging",
    "Advertising",
    "Measuring",
  ];
  return (
    <div>
      <Header />
      <div className="Searchengine-banner">
        <img src="assets/img/seo/seobanner1.png" alt="Searchengine"></img>

        <h1 className="callcenter-banner-text">
          Branding<span style={{ color: "#35bfff" }}>World</span>
          <br></br> Search Engine Optimization
        </h1>
        <p>
          Modernize your contact centre to facilitate effective communication
          between customers and businesses
        </p>
      </div>

      <div className="mt-2 align-items-center vertical-container row">
        <div className="col1 col-lg-2 vertical-text">
          <h1>Research</h1>
          <h1 className="inline-number">01</h1>
        </div>
        <div className="col2 col-lg-2 vertical-text">
          <h1>
            Planning and <br></br> Strategy
          </h1>
          <h1 className="inline-number">02</h1>
        </div>
        <div className="col3 col-lg-2 vertical-text">
          <h1>Implementation</h1>
          <h1 className="inline-number">03</h1>
        </div>
        <div className="col4 col-lg-2 vertical-text">
          <h1>Monitoring</h1>
          <h1 className="inline-number">04</h1>
        </div>
        <div className="col5 col-lg-2 vertical-text">
          <h1>Assessment</h1>
          <h1 className="inline-number">05</h1>
        </div>
        <div className="col6 col-lg-2 vertical-text">
          <h1>Maintenance</h1>
          <h1 className="inline-number">06</h1>
        </div>
      </div>

      <div className=" Including-Business-main">
        <div className="Including-Business container row">
          <div
            className="col-lg-4 
   mt-2 Including-Business-left"
          >
            <h2>01 Research</h2>
            <div className="col mb-4">
              Including business research, competitor analysis, current state
              assessment, and keyword searching
            </div>
            <h2> 02 Planning and Strategy</h2>

            <div className="col mb-4">
              Including business research, competitor analysis, current state
              assessment, and keyword searching
            </div>
            <h2>03 Implementation</h2>

            <div className="col mb-4">
              The optimization decisions on a site's webpages and the website as
              a whole are executed
            </div>
          </div>
          <div className="col-lg-4 px-3 mt-2">
            <img
              src="assets/img/seo/Seo_research.png"
              alt="seo"
              style={{ height: "380px", width: "100%", objectFit: "contain" }}
            />
          </div>
          <div className="col-lg-4 px-3 mt-2 Including-Business-right">
            <h2>06 Maintenance</h2>

            <div className="col mb-4">
              Both minor or major problems with the website's operation are
              handled as they arise
            </div>
            <h2>05 Assessment</h2>

            <div className="col mb-4">
              Involving checking the summarized effects of the strategy (and its
              implementation) against the SEO process's stated targets
            </div>
            <h2>04 Monitoring</h2>

            <div className="col mb-4">
              The activity of web spiders, traffic, search engine rankings, and
              other metrics are observed for producing reports on which
              assessment will be performed
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------------Thecinal seo------------------------------- */}

      <div class=" Thecinal-seo">
        <ol class="Technical-SEO-level-2-wrapper Thecinalseo-main">
          <li>
            <ol class="Technical-SEO-level-3-wrapper">
              <li>
                <h3 class="Technical-SEO-level-3 rectangle">Technical SEO</h3>
                <ol class="Technical-SEO-level-4-wrapper">
                  <li>
                    <h4 class="Technical-SEO-level-4 rectangle">Crawling</h4>
                  </li>
                  <li>
                    <h4 class="Technical-SEO-level-4 rectangle">Indexing</h4>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>

        <ol class="ON-Page-level-2-wrapper on-page">
          <li>
            <ol class="ON-Page-level-3-wrapper">
              <li>
                <h3 class="ON-Page-level-3 rectangle">ON Page SEO</h3>
                <ol class="ON-Page-level-4-wrapper">
                  <li>
                    <h4 class="ON-Page-level-4 rectangle">Page</h4>
                  </li>
                  <li>
                    <h4 class="ON-Page-level-4 rectangle">
                      Content
                      <br></br>Optimization
                    </h4>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <ol class="Off-Page-level-2-wrapper on-page">
          <li>
            <ol class="Off-Page-level-3-wrapper">
              <li>
                <h3 class="Off-Page-level-3 rectangle">Off Page SEO</h3>
                <ol class="Off-Page-level-4-wrapper">
                  <li>
                    <h4 class="Off-Page-level-4 rectangle">
                      Website<br></br> Poromotion
                    </h4>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>

      {/* ----------------------------------about improving------------------------------- */}
      <div className="container improving">
        <div className="improving-left">
          <img
            src="assets/img/seo/technical-seo.png"
            id="improving-left-1"
          ></img>

          <img
            src="assets/img/seo/technical-seo-1.png"
            id="improving-left-2"
          ></img>
          <p>
            Technical
            <br></br>SEO
          </p>
        </div>
        <div className="improving-right">
          <p>
            Technical SEO is about improving your website to make it easier for
            search engines to find, understand, and store your content.
          </p>
          <p>
            It also involves user experience factors, Such as making your
            website faster and easier to use on mobile devices.
          </p>
          <p>Technical SEO can boost your visibility in search results.</p>
        </div>
      </div>

      {/* ----------------------------------Website Structure------------------------------- */}
      <div className=" Website-Structure">
        <div className="Website-Structure-left">
          <img
            src="assets/img/seo/technical-seo-1.png"
            id="Website-Structure-left-2"
          ></img>
          <p>
            Technical
            <br></br>SEO
          </p>
        </div>
        <div className="Website-Structure-right">
          <div>
            <img src="assets/img/seo/round-1.png"></img>
          </div>
        </div>
      </div>
      {/* ----------------------------------optimizing-website------------------------------- */}
      <div className="optimizing-website ">
        <div className="optimizing-website-left">on-Page SEO</div>
        <div className="optimizing-website-right">
          <p>
            ON-page SEO ( also called on-site SEO ) is the process of optimizing
            website and their content for both search engines and users, It can
            help rank pages higher on Google and drive more organic traffic,
          </p>
          <p>
            Common tasks associated with on-page SEO include optimizing for
            search intent, title tags, internal links, and URLs
          </p>
        </div>
      </div>
      {/* ----------------------------------Trapezoid------------------------------- */}
      {/* <div className="Trapezoid">
        <div className="Trapezoid-leve1">
          <p>1</p>
          <p>Site Content</p>
          <p>2</p>
          <p>Key Words</p>
        </div>
        <div className="Trapezoid-leve2">
          <p>3</p>
          <p>Images</p>
          <p>4</p>
          <p>Internal and External Links</p>
        </div>
        <div className="Trapezoid-leve3">
          <p>5</p>
          <p>Title Tags</p>
          <p>6</p>
          <p>User Engagement</p>
        </div>
        <div className="Trapezoid-leve4">
          <p>7</p>
          <p>Meta Descriptions</p>
          <p>8</p>
          <p>Page Speed</p>
        </div>
        <div className="Trapezoid-leve5">
          <p>9</p>
          <p>Headers</p>
          <p>10</p>
          <p>Featured Snippets</p>
        </div>
        <div className="Trapezoid-leve6">
          <p>11</p>
          <p>URL’s</p>
          <p>12</p>
          <p>Schema Markup</p>
        </div>
      </div> */}
      <div class="ag-format-container">
        <div class="ag-courses_box">
          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                <p>
                  <span class="ag-courses-item_date">1 .</span>Site Content
                </p>
                <p>
                  <span class="ag-courses-item_date">2 .</span>Key Words
                </p>
              </div>
            </a>
          </div>
          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                <p>
                  <span class="ag-courses-item_date">3 .</span>Images
                </p>
                <p>
                  <span class="ag-courses-item_date">4 .</span>Internal and
                  External Links
                </p>
              </div>
            </a>
          </div>
          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                <p>
                  <span class="ag-courses-item_date">5 .</span>Title Tags
                </p>
                <p>
                  <span class="ag-courses-item_date">6 .</span>User Engagement
                </p>
              </div>
            </a>
          </div>
          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                <p>
                  <span class="ag-courses-item_date">7 .</span>Meta Descriptions
                </p>
                <p>
                  <span class="ag-courses-item_date">8 .</span>Page Speed
                </p>
              </div>
            </a>
          </div>
          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                <p>
                  <span class="ag-courses-item_date">9 .</span>Headers
                </p>
                <p>
                  <span class="ag-courses-item_date">10 .</span>Featured
                  Snippets
                </p>
              </div>
            </a>
          </div>
          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                <p>
                  <span class="ag-courses-item_date">11 .</span>URL’s
                </p>
                <p>
                  <span class="ag-courses-item_date">12 .</span>Schema Markup
                </p>
              </div>
            </a>
          </div>

          {/* <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                UX/UI Web-Design&#160;+ Mobile Design
              </div>

              <div class="ag-courses-item_date-box">
                Start:
                <span class="ag-courses-item_date">04.11.2022</span>
              </div>
            </a>
          </div>

          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                Annual package "Product+UX/UI+Graph designer&#160;2022"
              </div>

              <div class="ag-courses-item_date-box">
                Start:
                <span class="ag-courses-item_date">04.11.2022</span>
              </div>
            </a>
          </div>

          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">Graphic Design</div>

              <div class="ag-courses-item_date-box">
                Start:
                <span class="ag-courses-item_date">04.11.2022</span>
              </div>
            </a>
          </div>

          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">Motion Design</div>

              <div class="ag-courses-item_date-box">
                Start:
                <span class="ag-courses-item_date">30.11.2022</span>
              </div>
            </a>
          </div>

          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">
                Front-end development&#160;+ jQuery&#160;+ CMS
              </div>
            </a>
          </div>

          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>
              <div class="ag-courses-item_title">Digital Marketing</div>
            </a>
          </div>

          <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
              <div class="ag-courses-item_bg"></div>

              <div class="ag-courses-item_title">Interior Design</div>

              <div class="ag-courses-item_date-box">
                Start:
                <span class="ag-courses-item_date">31.10.2022</span>
              </div>
            </a>
          </div> */}
        </div>
      </div>
      {/* ----------------------------------off-seo------------------------------- */}
      <div className="off-seo ">
        <div className="off-seo-left">off-page SEO</div>
        <div className="off-seo-right">
          <p>
            Off- page SEO includes activities done off of a website in an effort
            to increase the site's search engine rankings, Common off-page SEO
            actions include building backlinks, encouraging branded searches and
            increasing engagement and shares on social media,
          </p>
          <p>
            The activities you perform off your website can influence how Google
            and other search engines perceive your own content. When done
            correctly, they can help your site appear more trustworthy and
            authoritative, which Google rewards.
          </p>
        </div>
      </div>
      {/* ----------------------------------arrow-flow------------------------------- */}
      {/* <div className="off-page-seo">
        <div className="circle center">Off-Page SEO</div>

        <div className="circle video-submission">Video Submission</div>
        <div className="circle articles">Articles</div>
        <div className="circle book-marking">Book Marking</div>
        <div className="circle blogs-posting">Blogs Posting</div>
        <div className="circle e-books">E Books</div>
        <div className="circle classified-ads">Classified Ads</div>
        <div className="circle directory-submission">Directory Submission</div>
        <div className="circle link-building">Link Building</div>

        <div className="arrow video-articles">&#10140;</div>
        <div className="arrow articles-book">&#10140;</div>
        <div className="arrow book-blogs">&#10140;</div>
        <div className="arrow blogs-ebooks">&#10140;</div>
        <div className="arrow ebooks-classified">&#10140;</div>
        <div className="arrow classified-directory">&#10140;</div>
        <div className="arrow directory-link">&#10140;</div>
        <div className="arrow link-video">&#10140;</div>
      </div> */}
      {/* <div class="arrow-chart">
        <section class="medium">
          <label>Video Submission</label>
        </section>
        <section class="low">
          <label>Articles</label>
        </section>
        <section class="high">
          <label>Book Marking</label>
        </section>
        <section class="high">
          <label>Blogs Posting</label>
        </section>
        <section class="medium">
          <label>E Books</label>
        </section>
        <section class="high">
          <label>Classified Ads</label>
        </section>
        <section class="medium">
          <label>Social Media</label>
        </section>
        <section class="low">
          <label>Infographics</label>
        </section>
      </div> */}

      {/* <figure>
        <ul>
          <li class="high">
            <span>High: Above 3.75</span>
          </li>
          <li class="medium">
            <span>Medium: Between 3.25 - 3.74</span>
          </li>
          <li class="low">
            <span>Low: Below 3.25</span>
          </li>
        </ul>
      </figure> */}
      {/* ----------------------------------social-media------------------------------- */}
      <div className="social-media ">
        <div className="social-media-left">
          <h1>Social Media Marketing</h1>
          <p>
            A powerful way for businesses of all sizes to reach prospects and
            customers, so if you're not on platforms like Facebook, Instagram,
            and LinkedIn, you're missing out! Great marketing on social media
            can bring remarkable success to your business, creating devoted
            brand advocates and even driving leads and sales
          </p>
        </div>
        <div className="social-media-right"></div>
      </div>

      {/* ----------------------------------wrapper------------------------------- */}
      <div className="monthly-plan">
        <h1>Monthly Scheduler</h1>
        <div className="monthly-plan-first">
          <div className="monthly-planfirst-1">
            <div className="monthly-planfirst-2">
              <img src="assets/img/seo/insta-1.png"></img>
            </div>
            <div className="monthly-planfirst-3">
              <p>4 Posters /</p>
              <p>2 Video's</p>
            </div>
          </div>
          <div className="monthly-planfirst-1">
            <div className="monthly-planfirst-2">
              <img src="assets/img/seo/fb-1.svg"></img>
            </div>
            <div className="monthly-planfirst-3">
              <p>4 Posters /</p>
              <p>2 Video's</p>
            </div>
          </div>
          <div className="monthly-planfirst-1">
            <div className="monthly-planfirst-2">
              <img src="assets/img/seo/youtube-2.png"></img>
            </div>
            <div className="monthly-planfirst-3">
              <p>2 Video’s</p>
              <p></p>
            </div>
          </div>
          <div className="monthly-planfirst-1">
            <div className="monthly-planfirst-2">
              <img src="assets/img/seo/in-2.png"></img>
            </div>
            <div className="monthly-planfirst-3">
              <p>4 Posters /</p>
              <p>2 Video's</p>
            </div>
          </div>
        </div>
        <div className="monthly-plan-second" id="monthly-plan-second-top">
          <div className="monthly-plansecond-1">
            <p>Accurate, complete, active, links to website and has CTA</p>
          </div>
          <div className="monthly-plansecond-1"></div>
          <div className="monthly-plansecond-1">
            <p>Like, share, comment, followers, influencers</p>
          </div>
          <div className="monthly-plansecond-1"></div>
          <div className="monthly-plansecond-1">
            <p>Use platform and website analytics to see what’s working</p>
          </div>
        </div>

        <div className="monthly-plan-wrapper">
          {labels.map((label, index) => (
            <div className="monthly-plan-arrow" key={index}>
              <span>{label}</span>
              <canvas
                className="monthly-plan-arrow"
                height="120"
                width="220"
                ref={(el) => (canvasRefs.current[index] = el)}
              ></canvas>
            </div>
          ))}
        </div>
        <div className="monthly-plan-second" id="monthly-plan-second-bottom">
          <div className="monthly-plansecond-1"></div>
          <div className="monthly-plansecond-1">
            <p>Useful, entertaining, relevant posts, videos, lives, stories</p>
          </div>
          <div className="monthly-plansecond-1"></div>
          <div className="monthly-plansecond-1">
            <p>Paid method of reaching targeted audiences</p>
          </div>
          <div className="monthly-plansecond-1"></div>
        </div>
      </div>
      {/* ----------------------------------search-engine-marketing------------------------------- */}
      <div className="search-engine-marketing ">
        <div className="search-engine-marketing-left">
          <h1>Search Engine Marketing</h1>
          <p>
            Search engine marketing, or SEM, is one of the most effective ways
            to grow your business in an increasingly competitive marketplace
          </p>
          <p>
            With millions of businesses out there all vying for the same
            eyeballs, it's never been more important to advertise online, and
            search engine marketing is the one of the most effective ways to
            promote your products and grow your business
          </p>
          <p>
            Google Ads, Facebook Ads, Instagram Ads, LinkedIn Ads, Email
            Marketing
          </p>
        </div>
        <div className="search-engine-marketing-right"></div>
      </div>
      {/* ----------------------------------seo-pricing-section------------------------------- */}

      <div class="container-fluid seo-price-top">
        <h1>Pricing</h1>
        <div class="container">
          <div class="row">
            <div class="col-sm-4">
              <div class="card text-center">
                <div class="seo-title">
                  <i class="fa fa-rocket" aria-hidden="true"></i>
                  <h2>Branding Pro</h2>
                </div>
                <div class="seo-price">
                  <h4>
                    <sup>₹</sup>690000/-
                  </h4>
                  <p>+GST</p>
                  <p>(Per Annual)</p>
                </div>
                <div class="seo-option">
                  <p>Mobile, eCommerce \Management App </p>{" "}
                  <p> Website - Dynamic Web </p>
                  <p>Logo Creation - 8 Samples</p>
                  <p>SEO\SMO : </p>
                  <p>Facebook\LinkedIn\Instagram\ keywords\campaing</p>
                  <p>Digital Advertising :</p>
                  <p>YouTube \ 2d Videos</p>
                </div>
                <Link to="/ContactUs">Contact Us</Link>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card text-center">
                <div class="seo-title">
                  <i class="fa fa-plane" aria-hidden="true"></i>

                  <h2>Branding Plus </h2>
                </div>
                <div class="seo-price">
                  <h4>
                    <sup>₹</sup>360000/-
                  </h4>
                  <p>+GST</p>
                  <p>(Per Annual)</p>
                </div>
                <div class="seo-option" id="seo-option-plus">
                  <p>Mobile, eCommerce \Management App </p>{" "}
                  <p> Website - Dynamic Web</p>
                  <p>SEO\SMO : </p>
                  <p>Facebook\LinkedIn\Instagram\ keywords\campaing</p>
                  <p></p>
                  <p></p>
                </div>
                <Link to="/ContactUs">Contact Us</Link>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card text-center">
                <div class="seo-title">
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  <h2>Branding Basic </h2>
                </div>
                <div class="seo-price">
                  <h4>
                    <sup>₹</sup>169000/-
                  </h4>
                  <p>+GST</p>
                  <p>(Per Annual)</p>
                </div>
                <div class="seo-option" id="seo-option-basic">
                  <p></p>
                  <p></p> <p> Website :Dynamic Web</p>
                  <p>SEO\SMO : </p>
                  <p>Facebook\LinkedIn\Instagram\ keywords\campaing</p>
                  <p></p>
                  <p></p>
                </div>
                <Link to="/ContactUs">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ----------------------------------seo-contact------------------------------- */}
      <div className="seo-contact">
        <div className="seo-contact-left">
          <div className="thankyoucontent">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <img
                  src="https://i.ibb.co/Lkn7rkG/thank-you-envelope.png"
                  alt="thank-you-envelope"
                  border="0"
                />

                <p>
                  Let's Thrive Together! Contact us today for transformative IT
                  solutions and unlock your organization's full potential in the
                  digital era
                </p>
                <h1>Thank you!</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="seo-contact-right">
          <blockquote class="q-card q-card-color-1">
            <div class="content">
              <p>Contact</p>
              <p>www.Branding World.com</p>
              <p>info@Branding World.com</p>
            </div>
          </blockquote>

          <blockquote class="q-card q-card-color-2">
            <div class="content">
              <p>Venkatraman R</p>
              <p>Sales & Strategic </p>
              <p>Initiatives</p>
              <p>venkat@Branding World.com | 98844 38954</p>
            </div>
          </blockquote>

          <blockquote class="q-card q-card-color-3">
            <div class="content">
              <p>Contact</p>
              <p>www.Branding World.com</p>
              <p>info@Branding World.com</p>
            </div>
          </blockquote>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Intelligence;
