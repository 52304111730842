import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";
// import Requestform from "../Businessapplication/Requestform";

const Home = () => {
  useEffect(() => {
    document.title = "Home | Branding World";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Great IT field experience Innovative IT solutions to help your business"
      );
  }, []);

  const apps = [
    {
      img: 'assets/img/img-1.avif',
      title: 'E-commerce app',
    },
    {
      img: 'assets/img/img-2.jpg',
      title: 'Billing-software',
    },
    {
      img: 'assets/img/travel.jpeg',
      title: 'MechUni - Online Mechanical Services',
    },
    {
      img: 'assets/img/crm-1.jpg',
      title: 'CRM - application',
    },
    {
      img: 'assets/img/service1.jpg',
      title: 'Service apps',
    },
    {
      img: 'assets/img/dress.jpg',
      title: 'Fashion Apps',
    },
  ];

  return (
    <div>
      <Header />

      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <section
              className="heroslider d-flex align-items-center"
              style={{
                backgroundImage:
                  "url('https://img.freepik.com/free-photo/people-creating-new-project_23-2147656719.jpg?t=st=1727346508~exp=1727350108~hmac=a4b716a99f49209f0bb009c2f86d552fdb721d1e97ec6e666c355f4763583b10&w=740')",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center"
                    // style={{
                    //   backgroundColor: "blue",
                    //   height: "500px",
                    //   borderRadius: "20px",
                    //   color: "white",
                    // }}
                  >
                    {/* <h3
                      style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}
                    >
                      Great IT field experience
                    </h3>
                    <h1 style={{ color: "black" }}>
                      Innovative IT solutions<br></br> to help your business
                    </h1> */}
                    <br></br>
                    {/* <h5 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                    We as Branding World is a professionally managed full service company having more than 10 years of experience in Digital Marketing, Software Development and Mobile App Development in Chennai. We as highly experienced E-commerce Website Development Company in Chennai utilize the updated knowledge of our qualified team to achieve goal sets of our clients in a professionally managed and analytically supported campaigns.
                    </h5> */}
                    {/* <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                    {/* <Requestform /> */}
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="carousel-item">
            <section
              className="heroslider d-flex align-items-center"
              style={{
                backgroundImage:
                  "url('https://img.freepik.com/free-photo/medium-shot-bored-people-working_23-2150697600.jpg?t=st=1727346706~exp=1727350306~hmac=9da8f9eba2d9b618909dab8d5cea0e3b28585cd8cbe9ed66e81f47f3f094873f&w=740')",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    {/* <h3
                      style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}
                    >
                      Simple Integration
                    </h3>
                    <h1 style={{ color: "black" }}>
                      Best IT Support for business success.
                    </h1> */}
                    <br></br>
                    {/* <h4 data-aos-delay="400" style={{ color: "black" }}>
                      Our Number GoalIs Become The Most Ruptable WorldWide
                      Provider of IT Solution{" "}
                    </h4> */}
                    {/* <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="carousel-item">
            <section
              className="heroslider d-flex align-items-center"
              style={{
                backgroundImage:
                  "url('https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241213.jpg?t=st=1727347356~exp=1727350956~hmac=bc3a80697ccb4bd225b696cbdce725cec738940c03e41f8be7bf42f6ca068bc0&w=996 ')",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    {/* <h3
                      style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}
                    >
                      Great IT field experience
                    </h3>
                    <h1 style={{ color: "black" }}>
                      Innovative IT solutions<br></br> to help your business
                    </h1> */}
                    <br></br>
                    {/* <h4 data-aos-delay="400" style={{ color: "black" }}>
                      Our Number GoalIs Become The Most Ruptable WorldWide
                      Provider of IT Solution{" "}
                    </h4> */}
                    {/* <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden" style={{ color: "black" }}>
            Previous
          </span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <section id="services" className="services pb-5">
        <div className="container">
          <header className="section-header">
            <h3 style={{ color: "hsl(249  100%  61%)", fontWeight: "800" }}>
              Professional IT services for your business
            </h3>
            <p className="heading">
              We provide a wide range of IT services <br></br>that ensure your
              company's success.
            </p>   
          </header>
          
        </div>
      </section>



      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <div className="container">
                <div className="row gy-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(124, 65, 251)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
                      <div className="info-box align-items-center justify-content-center">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "80px",
                          }}
                        >
                          <img
                            src="assets/img/icon-5.png"
                            alt="Icon"
                            className="img-fluid"
                            style={{
                              height: "50px",
                              width:'50px',
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <br></br>
                        <h4>Mobile app Development</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(30, 200, 146)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
                      <div className="info-box">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "50px",
                          }}
                        >
                          {" "}
                          <img
                            src="assets/img/icon-6.png"
                            style={{ height: "50px", objectFit: "cover" }}
                          />
                        </div>
                        <br></br>
                        <h4 style={{ textAlign: "center" }}>
                          Web Development
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(255, 66, 104)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
                      <div className="info-box">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            src="assets/img/icon-2.png"
                            alt="icon"
                            style={{ height: "50px", objectFit: "none" }}
                          />
                        </div>
                        <h4 style={{ textAlign: "center", margin: "0" }}>
                          Digital Marketing
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgb(255, 187, 11)",
                        height: "200px",
                        borderRadius: "20px",
                        color: "hsl(0 0% 100%)",
                      }}
                    >
 <div className="info-box">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "white",
                            height: "80px",
                            width: "80px",
                            borderRadius: "40px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            src="assets/img/icon-3.png"
                            alt="icon"
                            style={{ height: "50px", objectFit: "none" }}
                          />
                        </div>
                        <h4 style={{ textAlign: "center", margin: "0" }}>
                          SEO
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              style={{}}
            >
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                What Makes <b>Branding World</b> Stand Out
              </h3>
              <h1>
                Digital Technology, IT Solutions, and Service Providers
                Worldwide
              </h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Bring win-win survival techniques to the table in order to
                assure dotted proactive control. At the end of the day, moving
                ahead, there will be a new normal that has developed.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="#about"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                   <Link to='/Service'> <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      Learn More
                    </span></Link>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                We provide a wide range of services to meet your needs.
              </h3>
              <br></br>
              <h1 style={{ textAlign: "justify" }}>
                Get a Free Consultation on YourUpcoming Project Here!
              </h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                If you have a project or idea, contact or make an appointment.
                Our rep will soon respond.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <div className="text-center text-lg-start">
                    <Link
                      to="/ContactUs"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Let's Talk Now</span>
                      <i className="bi bi-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/consult.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div> */}



          <div className="row">
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/consult.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                We provide a wide range of services to meet your needs.
              </h3>
              <br />
              <h1 style={{ textAlign: "justify" }}>
                Get a Free Consultation on Your Upcoming Project Here!
              </h1>
              <br />
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                If you have a project or idea, contact or make an appointment.
                Our rep will soon respond.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <Link
                    to="/ContactUs"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Let's Talk Now</span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <div className='container text-center mt-4 mb-4'>
        <h1>Our portfolio</h1>
        <p>IMPROVE YOUR BUSINESS IDEAS WITH INNOVATION</p>
      <div className="app-showcase">
      {apps.map((app, index) => (
        <div className="app-card" key={index}>
          <img src={app.img} alt={app.title} className="app-image" />
          <h3 className="app-title">{app.title}</h3>
        </div>
      ))}
    </div>
    </div>





      <div className="container values" id="values">
        <div className="row">
          <div className="col-lg-3" data-aos-delay="200">
            <div className="box">
              <img
                src="assets/img/features-3.png"
                style={{ height: "180px" }}
                className="img-fluid"
                alt=""
              />
              <h3>Top Flexibility</h3>
              <p className="text-justify">
                Our IT solutions are designed to be flexible and scalable, so
                you can easily adapt them to your changing needs.
              </p>
            </div>
          </div>

          <div className="col-lg-3 mt-4 mt-lg-0" data-aos-delay="400">
            <div className="box">
              <img
                src="assets/img/values-2.png"
                className="img-fluid text-justify"
                alt=""
              />
              <h3>Time Saving</h3>
              <p>
                Our IT solutions can help you save time and money by automating
                tasks and streamlining processes.
              </p>
            </div>
          </div>

          <div className="col-lg-3 mt-4 mt-lg-0 " data-aos-delay="600">
            <div className="box ">
              <img
                src="assets/img/values-3.png"
                className="img-fluid text-justify "
                alt=""
              />
              <h3>Management</h3>
              <p className="text-justify">
                Our IT solutions provide you with the tools you need to manage
                your IT infrastructure effectively.
              </p>
            </div>
          </div>
          <div className="col-lg-3 mt-4 mt-lg-0" data-aos-delay="600">
            <div className="box">
              <img
                src="assets/img/values-1.png"
                className="img-fluid text-justify"
                alt=""
              />
              <h3>Collaborative</h3>
              <p className="text-justify">
                Our IT solutions are designed to promote collaboration and
                communication between employees.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
