import React, { useEffect, useState } from "react";
import Apiservice from "../Businessapplication/Apiservice";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import Requestform from "../Businessapplication/Requestform";

const Cybersecurityproducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Apiservice.fetchProductList()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product list:", error);
      });
  }, []);
  return (
    <div>
      <Header />
      {/* ------------------------------------------Cyber-products-banner-------------------------------------- */}

      <div className="Cyber-products-banner">
        <img
          src="assets/img/cyberproducts/cyberproduct-banner.png"
          alt="cyberproducts"
        ></img>

        <h1 className="Cyber-products-banner-text">Cyber Security products</h1>
      </div>
      {/* ------------------------------------------ Branding World Partners-------------------------------------- */}
      <div className="Cyber-products-first container">
        <div className="Cyberproductsfirst-left">
          <h1>
            Branding World Partners with top cybersecurity products company and
            brings the best of solution to make user your Digital Environment is
            safely protected
          </h1>
        </div>
        {/* ------------------------------------------*Request form-------------------------------------- */}
        <div className="Cyber-products-first-right">
          <Requestform />
        </div>
      </div>
      {/* ------------------------------------------simply-secure-------------------------------------- */}
      <div className="container simply-secure ">
        <div className="simply-secure-main">
          <h1>Work</h1>
          <h1>HOME</h1>
          <h1>AnyWhere!</h1>
        </div>
        <h1>Simply Secure Human Intelligence</h1>
        <p>Eliminate insider threats secure data</p>
        <div className="simply-secure-row">
          <div className="simply-secure-row-left">
            <div className="simply-secure-row-left-content">
              <div className="simply-secure-rowleft-text">
                <h1>Access to network data Where Who & What</h1>
              </div>
              <div className="simply-secure-rowleft-image">
                <img src="assets/img/cyberproducts/armariuscenter.png"></img>
                <p>
                  Monitor and
                  <br></br>Manage Data
                  <br></br>{" "}
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Sensitive
                  </span>
                </p>
              </div>
            </div>
            <div className="simply-secure-row-left-content">
              <div className="simply-secure-rowleft-text">
                <h1>
                  Electronically enforcement Individual or Group Policies 
                </h1>
              </div>
              <div
                className="simply-secure-rowleft-image"
                id="simply-secure-rowleft-image-center-first"
              >
                <img src="assets/img/cyberproducts/armariuscenter.png"></img>
                <p>
                  Enforce IT &<br></br> HR Policy
                  <br></br>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Control
                  </span>
                </p>
              </div>
            </div>
            <div className="simply-secure-row-left-content">
              <div className="simply-secure-rowleft-text">
                <h1>
                  GDPR + PII + HIPAA GLBA + SEC + SOX + ISO 27001 + NIST CMMC 
                </h1>
              </div>
              <div className="simply-secure-rowleft-image">
                <img src="assets/img/cyberproducts/armariuscenter.png"></img>
                <p>
                  Regulatory
                  <br></br> Compliance
                  <br></br>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Comply
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="simply-secure-row-center">
            <div className="simply-secure-row-center-one">
              <img src="assets/img/cyberproducts/armariuscenter.png"></img>
            </div>
            <div className="simply-secure-row-center-two">
              <img src="assets/img/cyberproducts/armariuscenterlogo.png"></img>
            </div>
            <div className="simply-secure-row-center-three">
              <img src="assets/img/cyberproducts/armariuscricle.png"></img>
            </div>
            <div className="simply-secure-row-center-three-text">
              <div className="simply-secure-row-center-four">
                <p>User Productivity & Management</p>
              </div>
              <div className="simply-secure-row-center-five">
                <p>Proactive Data Loss Prevention</p>
              </div>
              <div className="simply-secure-row-center-six">
                <p>Secure Sensitive Data</p>
              </div>
            </div>
          </div>
          <div className="simply-secure-row-right">
            <div className="simply-secure-row-left-content">
              <div className="simply-secure-rowleft-image">
                <img src="assets/img/cyberproducts/armariuscenter.png"></img>
                <p>
                  Productivity
                  <br></br>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Know
                  </span>
                </p>
              </div>
              <div className="simply-secure-rowright-text">
                <h1>
                  From Log on to log off Employee & Application performance 
                </h1>
              </div>
            </div>
            <div className="simply-secure-row-left-content">
              <div
                className="simply-secure-rowleft-image"
                id="simply-secure-rowleft-image-center"
              >
                <img src="assets/img/cyberproducts/armariuscenter.png"></img>
                <p>
                  Complete
                  <br></br>and Secure
                  <br></br>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Proactive
                  </span>
                </p>
              </div>
              <div className="simply-secure-rowright-text">
                <h1>Proactive  DLP & EPP & Phishing Forensic</h1>
              </div>
            </div>
            <div className="simply-secure-row-left-content">
              <div className="simply-secure-rowleft-image">
                <img src="assets/img/cyberproducts/armariuscenter.png"></img>
                <p>
                  Analytics
                  <br></br> and Reports
                  <br></br>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Automate
                  </span>
                </p>
              </div>
              <div className="simply-secure-rowright-text">
                <h1>Automated Internal/External Audit & Reports</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="simply-secure-footer">
        <img src="assets/img/cyberproducts/armariusbanner.png"></img>
      </div>
      {/* ------------------------------------------Cyber-products-banner-------------------------------------- */}

      <div className="Cyber-products-cricle-main container">
        <img src="assets/img/cyberproducts/wallixone.png" alt="img"></img>
        <div className="Cyber-products-cricle container">
          <di className="Cyberproducts-cricle-left">OT</di>

          <div class="logo">
            <div class="ring blue">
              <p>Acess Management and authentication</p>
            </div>
            <div class="ring yellow">
              <p>Privileged access management</p>
            </div>
            <div class="ring black">
              <p>Identity Governance</p>
            </div>
            <div class="ring green">
              <p>Remote access</p>
            </div>
            <div class="ring red">
              <p>Privilege elevation</p>
            </div>
          </div>
          <div className="Cyberproducts-cricle-right">IT</div>
        </div>
        <h1 className="Cyber-products-cricle-footer">
          On prem <span style={{ color: "#ec6706" }}>/</span> Cloud
          <span style={{ color: "#ec6706" }}>/</span> Hybrid
        </h1>
      </div>
      {/* ------------------------------------------Wallix-------------------------------------- */}
      <div className="Wallix container">
        <div className="Wallix-main-left">
          <img src="assets/img/cyberproducts/wallix.png"></img>
        </div>
        <div className="Wallix-main-right">
          <div className="Wallix-main-right-first">
            <div className="Wallix-main-rightfirst-one">
              <div className="Wallix-main-rightfirst-onemain">
                <div className="wallix-number">
                  <h1>1</h1>
                </div>
                <div className="wallix-text">
                  <h1>External service providers</h1>
                </div>
              </div>
              <ul>
                <li>
                  I have no visibility on what my providers are doing on the
                  infrastructure
                </li>
                <li>
                  Many people access servers, devices and applications: I do not
                  know who has access to what, when or how
                </li>
                <li>
                  I must control these accesses and change external provider if
                  I need to
                </li>
              </ul>
              <p>
                How can I ensure full access control? How can I find the origin
                of the problem? Who is responsible?
              </p>
            </div>
            <div className="Wallix-main-rightfirst-two">
              <div className="Wallix-main-rightfirst-onemain">
                <div className="wallix-number">
                  <h1>2</h1>
                </div>
                <div className="wallix-text">
                  <h1>Admin Passwords</h1>
                </div>
              </div>

              <ul>
                <li>
                  Post-it notes multiply on computer screens or on the desks or
                  in unsecured Excel file
                </li>
                <li>
                  Passwords are handled chaotically. Sometimes, they are only in
                  the admin’s head
                </li>
                <li>
                  Generic accounts (Admin & Root) are not longer an option
                </li>
              </ul>
              <p>What is the best way to handle user authentication?</p>
            </div>
          </div>
          <div className="Wallix-main-right-second">
            <div className="Wallix-main-rightfirst-one">
              <div className="Wallix-main-rightfirst-onemain">
                <div className="wallix-number">
                  <h1>3</h1>
                </div>
                <div className="wallix-text">
                  <h1>When an incident happens</h1>
                </div>
              </div>

              <ul>
                <li>Origin of an incident and traceability of actions</li>
                <li>
                  The customer database crashed after a support intervention
                  from an external provider during a major upgrade
                </li>
                <li>We cannot establish responsibilities or find evidence!</li>
              </ul>
              <p>
                Where did the problem come from? Can we review what happened?
                How can we determine the origin of the problem?
              </p>
            </div>
            <div className="Wallix-main-rightfirst-two">
              <div className="Wallix-main-rightfirst-onemain">
                <div className="wallix-number">
                  <h1>4</h1>
                </div>
                <div className="wallix-text">
                  <h1>IT Teams turnover</h1>
                </div>
              </div>

              <ul>
                <li>One of my admins is leaving the company</li>
                <li>
                  His/her access rights must be listed, deactivated and modified
                  for every device
                </li>
                <li>These changes must be communicated internally</li>
              </ul>
              <p>
                How can I make sure he/she will no longer be able to access to
                the information system?
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------------blue-print-------------------------------------- */}
      <div className="blue-print">
        <h1 id="blue-print-h1">PAM Architecture</h1>
        <div className="blue-print-certificate">
          <div className="blue-print-certificatefirst-left">
            <img src="assets/img/cyberproducts/ceritificate3.png"></img>
          </div>
          <div className="blue-print-certificatefirst-right">
            <h1>Certified by ANSSI</h1>
            <p>
              French cybersecurity<br></br>compliance body
            </p>
          </div>
          <div className="blue-print-certificatefirst-right">
            <img src="assets/img/cyberproducts/ceriticate4.png"></img>
          </div>
          <div className="blue-print-certificatefirst-right">
            <h1>Certified by ANSSI</h1>
            <p>
              French cybersecurity <br></br>compliance body
            </p>
          </div>
        </div>
        <h5>NO NEED OF VPN/ ONE PORT ADMIN ACCESS / BLOCK RDP & SSH</h5>
        <div className="container blue-print-footer">
          <img src="assets/img/cyberproducts/blueprint1.png"></img>
        </div>
        <div className=" blue-print-footcontent">
          <div className=" blue-print-footcontent-end">
            <h1>CONFIDENTAL/</h1>
            <img src="assets/img/cyberproducts/wallix.png"></img>
          </div>
        </div>
      </div>
      {/* ------------------------------------------backend-------------------------------------- */}

      {products.length > 0 ? (
        products.map((product, index) => (
          <div className="backend container" key={index}>
            <div className="backend-image">
              <img src={product.image} alt={product.name} />
            </div>
            <div className="backend-text">
              <h1>{product.name}</h1>
              <p>{product.description}</p>
            </div>
          </div>
        ))
      ) : (
        <p>Loading products...</p>
      )}
      <Footer />
    </div>
  );
};

export default Cybersecurityproducts;
