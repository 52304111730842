import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Service = () => {
  return (
    <div>
      <Header />
      <div className="callcenter-banner">
        <img
          src="assets/img/callcenterbanner.png"
          alt="cybersecurity"
          style={{
            background:
              "linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
          }}
        />
      </div>
      <section id="services" className="services" style={{ marginTop: "50px" }}>
        <div className="container">
          <header className="section-header">
            <h3 style={{ color: "hsl(249  100%  61%)", fontWeight: "800" }}>
              Professional IT services for your business
            </h3>
            <p style={{ fontSize: "50px", lineHeight: "60px" }}>
              We provide a wide range of IT services <br></br>that ensure your
              company's success.
            </p>
            <br></br>
            <h5>
              We are committed to offering IT services to small and medium-sized
              <br></br> businesses with enterprise-level scalability and
              efficiency.
            </h5>
          </header>

          <div
            className="row gy-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-4 col-md-6" data-aos-delay="200">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Mobile app Development</h3>
                <p>
                  This development encompasses creating user-friendly,
                  responsive, and high-performing applications tailored to meet
                  various needs, including entertainment, business, education,
                  health, and more.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="300">
              <div className="service-box orange">
                <i className="ri-discuss-line icon"></i>
                <h3>IT Consulting & Service</h3>
                <p>
                  Maximize your IT potential. Unlock growth opportunities with
                  our expert guidance and comprehensive services.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos-delay="700">
              <div className="service-box pink">
                <i className="ri-discuss-line icon"></i>
                <h3>Business Application</h3>
                <p>
                  Streamline operations. Empower your business with powerful
                  software solutions tailored to your needs..
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="400">
              <div className="service-box green">
                <i className="ri-discuss-line icon"></i>
                <h3>Digital Experience</h3>
                <p>
                  Unlock insights for success. Make data-driven decisions with
                  our robust analytics and reporting tools
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="600">
              <div className="service-box purple">
                <i className="ri-discuss-line icon"></i>
                <h3>R-to-Website</h3>
                <p>
                  Convert your R code to a dynamic website. Share your analyses
                  and visualizations with the world effortlessly.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="700">
              <div className="service-box pink">
                <i className="ri-discuss-line icon"></i>
                <h3>Web Development</h3>
                <p>
                  We provide the most responsive and functional IT solution to
                  enterprises and businesses all around the globe..
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Service;
